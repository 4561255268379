import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from "react-redux";
import App from './App';
import store from "./store";
import { HashRouter } from 'react-router-dom';
import { ApolloProvider } from "@apollo/client";
import client from './apollo/client';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <HashRouter basename='/'> {/* The basename replaced in the Docker build stage. */}
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </HashRouter>
  </Provider>
);
